.memberNames {
    width: 100%;
    overflow: ellipse;
}
.memberNames span::after {
    content: ' / '
}
.userClubData, .userManageGroupData {
    display: grid;
    grid-template-columns: 0.2fr auto 0.1fr;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid lightgrey;
    padding: 20px 0;
}
.userManageGroupData{
    padding: 7px 0;
    margin: 5% 0;
}
.userManageGroupData .MuiTab-textColorInherit.Mui-selected{font-weight: bold;}
.userClubData .userClubImg img, .userManageGroupData .userManageGroupImg img {
    height: 100px;
    width: 100px;
    border-radius: 40%;
}

.userManageGroupData .userManageGroupName h4,
.userManageGroupData .userManageGroupName p {
    margin: 0;
}

.ClubMembersList, .GroupMembersList {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.MemberData, .GroupMemberData {
    display: grid;
    justify-content: center;
    text-align: center;
    position: relative;
}
.GroupMemberData{
    margin-bottom:28%;
}
.GroupMemberData p{
    font-weight: 700;
    margin-bottom: 6%;
}
.GroupMemberData span{
    color:gray
}
.MemberData img {
    height: 100px;
    width: 100px;
    border-radius: 40%;
    border: 5px solid black;
}
.GroupMemberData img{
    height: 90px;
    width: 90px;
    border-radius: 40%;
    border: 4px solid black;
}
.MemberData p {
    font-weight: bold;
}

.ClubManageBtn .MuiPaper-elevation4, .userGroupManageBtn .MuiPaper-elevation4 {
    box-shadow: none;
}
.member-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}
.userManageGroupData .MuiTabs-scroller{
    position: inherit;
}
/* DesignGroupPageView css */
.groupChip{
    margin-left: 10px;
    background: white !important;
    border: 1px solid gray !important;
    border-radius: 6px !important;
}
.userGroupData .userGroupImg img {
    height: 70px;
    width: 70px;
    border-radius: 40%;
}
.userGroupData {
    display: grid;
    grid-template-columns: 0.2fr 1.2fr 0.4fr;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px solid lightgrey;
    padding: 20px 0;
}
.create_grp_btn{
    border: 1px solid black;
    height: 44px;
    width: fit-content;
    margin-top: 2%;
    font-weight: bolder;
    background-color:white;
    padding:2px 16px;
    border-radius: 8px;
    cursor: pointer;
}
.group_link{
    text-decoration: none;
    color: black;
}
.GroupManageBtn .MuiPaper-elevation4 {
    box-shadow: none;
}
.GmemberNames{
    width: 100%;
    text-overflow: ellipsis;
    color: dimgray;
    line-height: 0px;
}
.GmemberNames span::after {
    content: ' / '
}
.update_btn_div{
    display: grid;
    grid-template-columns: 20% auto 20%;
    column-gap: 20px;
    margin: 15px auto;
}
.manageClubBtn:hover, .manage_btn_group_admin:hover {
    background-color: black !important;
    color: white;
    cursor: pointer;
}
.manage_btn_group:hover {
   cursor: not-allowed;
}
input:focus-visible{
    outline: transparent;
}
