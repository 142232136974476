@font-face {
  font-family: 'Calibri';
  src: url('../fonts/Calibri.woff2') format('woff2'),
    url('../fonts/Calibri.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/Calibri-Light.woff2') format('woff2'),
    url('../fonts/Calibri-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/Calibri-Bold.woff2') format('woff2'),
    url('../fonts/Calibri-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Calibri' !important;
}

* {
  box-sizing: border-box;
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(221, 15, 15, 0.6);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(230, 74, 31, 0);
  }
}

.black-svg{
  filter: invert(0%) sepia(100%) saturate(32%) hue-rotate(267deg) brightness(104%) contrast(102%);
}

.white-svg{
  filter: invert(100%) sepia(3%) saturate(0%) hue-rotate(274deg) brightness(106%) contrast(106%);
}

.activeButtonAbout {
  background-color: black !important;
  color: white !important;
}

.gray-svg{
  filter: invert(51%) sepia(0%) saturate(1867%) hue-rotate(159deg) brightness(90%) contrast(85%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.deziner-scroll-bar::-webkit-scrollbar {
}

.deziner-scroll-bar::-webkit-scrollbar {
    cursor: pointer;
    width: 1em;
    height: 15px;
}

.deziner-scroll-bar::-webkit-scrollbar-track:hover {
    cursor: hover;
}

.deziner-scroll-bar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
}
/*
.deziner-scroll-bar:hover::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
}
*/
.deziner-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px;
}
/*
.deziner-scroll-bar:hover::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px;
}
*/



