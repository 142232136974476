.collection-container {
  display: grid;
  /* grid-template-columns: 250px calc(100vw - 360px); */
  align-items: center;
  margin-bottom: 40px;
  grid-gap: 20px;
}

.collection-slider {
  padding: 0 20px;
}

.collection-cover {
}

.collection-cover-empty {
}

.collection-cover>img, .collection-cover>video{
}


.slick-track {
  margin-left: 0;
}

@media screen and (max-width: 768px) {
  .collection-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .collection-slider {
    margin: 10px 0;
    max-width: calc(100vw - 100px);
    width: calc(100vw - 100px);
  }

  .collection-cover,
  .collection-cover img,
  .collection-cover-empty {
  }
}

/*
 *
 *
 */


.post-image-card {
  background: #F3F4F6;
  height: 150px;
  width: 90%;
  border-radius: 15px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.post-image-card img, .post-image-card video{
  border-radius: 15px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.post-image-card .post-image-card-overlay {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 20px;
  transition: all 0.5s ease;
  flex-direction: column;
  justify-content: space-between;
}

.post-image-card:hover>.post-image-card-overlay {
  display: flex;
}

.post-image-card .post-image-card-overlay h4 {
  margin: 0;
}

.post-image-card .post-image-card-overlay .post-image-card-details {
  font-size: 14px;
  font-weight: 500;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  align-items: center;
}

.post-image-card .post-image-card-overlay .post-image-card-details div:nth-last-child(-n+2) {
  justify-self: end;
}

.post-image-card .post-image-card-overlay .post-image-card-details>div>span {
  padding: 0 4px;
}

.post-image-card .post-image-card-overlay .post-image-card-details>div {
  display: flex;
  align-items: center;
}

.post-image-card .post-image-card-overlay .post-image-card-details p {
  margin: 0;
}
