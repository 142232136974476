.collection-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.collection-view-header div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
}

.collection-view-header div:first-of-type span {
    padding: 0 5px;
}

.collection-view-header-icon {
    max-width: 20px;
}