
/* ################ Forum css ################# */

.forum_main{background: #f7f7f7;}
.forum_subheader{
    margin-top: 3%;
    margin-bottom:3%
}
.forum_pic{
    max-width: 100%;
    max-height: 100%;
}
.subheader_right_div .MuiInputBase-root{
    background: white; 
    height:38px
}
.subheader_middle_div button{
    background: black;
    color: white;
    border-radius: 38px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.subheader_middle_div p{
    margin-left: 8px;
}
#chat_icon{
    height:40px;
}

/* topic form */
.create_topic{
    display: block;
    margin: auto;
    background: white;
    box-shadow: 0px 2px 9px -1px rgb(0 0 0 / 14%);
    border-radius: 8px;
}
#label_text{
    font-weight:700;
    margin-right: 5%;
}
#media_div{
    margin-top: 8%;
}
#divider{
    margin: 3% auto;
    width: 46%;
}
#buttons_div{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}
#buttons_div button{border-radius: 25px;}
#error{color:red}

/* ################# Common css of Forum and Forum Single ##################### */

.card_div_second .first{
    display: flex;
    align-items: center;
}
.card_div_first p{
    color:gray
}
.card_div_second .first span{
    color:black; 
    font-weight:700
}
.card_div_second img{
    height:40px;
    border-radius: 50px;
    margin-right: 10px;
}

/* ################ Forum single css ##################### */

.forum_single .MuiPaper-elevation1{
    box-shadow: none;
}
.comments_list{
    display:flex;
    justify-content: space-between;
    clear: both;
}
.comments_list img{
    height:55px;
    border-radius: 50px;
    margin-top:28%
}
.comments_list div:nth-child(2){
    margin-left: 2%;
    flex-grow: 1;
}
.comments_list div:nth-child(2) span{
    color: gray;
    margin-left: 4%;
}
.comments_list div:nth-child(2) p:nth-child(1){
    font-weight: bold;
}
.comments_list div:nth-child(2) p:nth-child(2){
    line-height: 22px;
}
#single_buttonsDiv{
    display: flex;
    justify-content: end;
    gap: 20px;
    margin: 4% 0%;
}
.comments_list #right_div{
    float:right;
    display:flex;
    gap:20px
}
.comments_list #left_div{
    float:left;
    font-weight:bold;
    font-size: 14px;
}
.comments_list #right_div span{
    cursor: pointer;
}
.forum_single_main .card_div_first span{
    color: black;
    font-weight: 700;
}
.forum_single_pic{
    max-width: 100%; 
    max-height: 100%; 
    border-radius: 10px;
    margin: 2% 0%;
}


/* target only mobile device css */
@media (max-width:767px){

/* forum css start*/
.MuiCardActions-root .MuiIconButton-root{
    padding:7px;
}
.forum_listing{
    margin: 5% auto;
}
.subheader_middle_div button{
    padding: 0px 21px;
}
.subheader_left_div, .subheader_middle_div, .subheader_right_div{
    display:flex;
    align-items:center;
    justify-content: center;
    margin: 12% auto;
}
.create_topic{
    padding: 37px 20px;
}
/* forum css end */

}

/* target only desktop device css */
@media (min-width: 1025px){

/* forum css start*/
.card_div_first, .card_div_second{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forum_listing,  .forum_single{
    width: 80%;
    display: block;
    margin: 4% auto;
}
.forum_subheader{
    display: flex;
    justify-content: space-around;
}
.subheader_left_div{
    display: flex;
    align-items: center;
}
.subheader_middle_div button{
    padding: 0px 42px;
    font-size: 16px;
}
.create_topic{
    width: 56%;
    padding: 37px 75px;
}
#buttons_div button{
    width: 15%;
}
/* forum css end */

/* forum single start */
.forum_single{width: 90%;}
}