.userData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  margin-top: 20px;
  grid-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.userDataInfo {
  display: grid;
  grid-template-columns: 0.1fr auto 0.2fr;
  align-items: center;
  column-gap: 10px;
}
.userDataInfoGroup {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr 0.6fr;
  align-items: center;
  column-gap: 10px;
}
.userDataInfo .userDataImg img {
  height: 100px;
  width: 100px;
  border-radius: 40%;
}
.userDataInfoGroup .userDataImg img {
  height: 80%;
  width: 80%;
  border-radius: 40%;
}
.designerDesign {
  display: grid;
  grid-template-columns: 20% auto 20%;
  /* justify-content: space-around; */
  column-gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.designerDesignOptions {
  display: grid;
  justify-content: right;
}
.designerDesignOptions svg {
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 10px 0px;
}

.desingerDesignSubImage {
  display: grid;
  row-gap: 15px;
  overflow: scroll;
}

.desingerDesignSubImage img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid white;
  cursor: pointer;
}
.desingerDesignSubImage img.active {
  border: 3px solid black;
}
.designerDesignMainImage {
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
}
.designerDesignMainImage img {
  height: 550px;
  width: 100%;
  object-fit: cover;
}

.designerDesingData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  margin-top: 20px;
}

.DesignDataAttr {
  display: flex;
  justify-content: start;
  align-items: center;
}

.DesignDataAttr p:first-child {
  padding: 0 30px 0 0;
  position: cursor;
}
.DesignDataAttr p {
  display: flex;
  align-items: center;
  column-gap: 7px;
  border-right: 1px solid black;
  padding: 0 30px;
}

.DesignDataAttr p:last-child {
  border-right: none;
}

.designDescription2 {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  font-weight: bold;
  margin: 5rem 0px;
}

.designerData {
  display: grid;
  text-align: center;
  position: relative;
}

.designerData::before {
  content: "";
  border: 0.5px solid black;
  width: 100%;
  top: 25%;
  position: absolute;
}

.designerData img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  z-index: 9;
  border-radius: 40px;
}

.promotePopup {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.promoteContainer {
  background-color: white;
  padding: 20px;
  width: 30vw;
}

.promoteContainer .promoteTitle img {
  margin-top: -5%;
  height: 100%;
  width: 350px;
}
.MuiSlider-markLabel {
  white-space: pre-wrap !important;
}
.containerTitle {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.promoteMainContainer {
  padding: 10px 30px;
}
.MuiSlider-marked {
  margin: 20px 0 !important;
}

.promoteSummary,
.promoteFooter {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.promoteTitle {
  background: lightgrey;
  margin: -20px;
  text-align: center;
  height: 180px;
}

.promotePopupCloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.containerFormControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerFormControl p {
  margin-bottom: 5px;
  font-weight: bold;
}

.DesignPromoteBtn {
  display: flex;
  column-gap: 20px;
}
.submitPost {
  margin: 20px 0;
  display: flex;
  justify-content: right;
  column-gap: 20px;
}
.moreDesigns {
  margin: 5rem 0px;
}
.moreDesigns .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.moreDesigns .designsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  column-gap: 20px;
}

.moreDesignData {
  display: grid;
  cursor: pointer;
}

.moreDesignData > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.moreDesignData > img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

.moreDesignData > div > p > span {
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  margin: 0 5px;
}

p.DesignerInspired {
  position: relative;
}

p.DesignerInspired:hover > ul.DesignerInspiredul {
  display: block;
}

ul.DesignerInspiredul:hover {
  display: block;
}

ul.DesignerInspiredul {
  display: none;
  position: absolute;
  top: 40px;
  /* width: 100%; */
  padding-left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  left: -8px;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 2;
  padding: 5px;
}

ul.DesignerInspiredul::before {
  content: "";
  height: 20px;
  width: 20px;
  /* background: rgba(0, 0, 0,0.8); */
  position: absolute;
  top: -19.5px;
  /* transform: rotate(45deg); */
  /* z-index: 1; */
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid rgba(0, 0, 0, 0.8);
}

.DesignerInspired li {
  list-style: none;
  display: flex;
  width: 180px;
  column-gap: 10px;
  padding: 5px;
  /* border-bottom: 1px solid white; */
  padding: 7px;
  /* border-radius: 10px; */
  align-items: center;
}

.DesignerInspired li img {
  height: 30px;
  width: 30px;
  border-radius: 40%;
}
.download_modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: -15px;
}
#popup-dialog-title h2 {
  font-weight: 700;
}
.close_icon_modal {
  position: absolute;
  right: 7px;
  top: 19px;
  color: darkgray;
  cursor: pointer;
}
.download_dialog .MuiDialogContent-root {
  margin-bottom: 10%;
}

/* design buy css */
.plan_buttons {
  display: flex;
  justify-content: space-evenly;
}
.continue_buy {
  display: flex;
  justify-content: center;
  margin: 8% 0%;
}
#buy_text {
  text-align: center;
  margin: 9% 0%;
  color: dimgray;
  letter-spacing: 0.8px;
}
#buy_text span {
  font-weight: 900;
  font-size: 24px;
  color: black;
}
.active_btn {
  border: 3px solid black !important;
}
#inputfield {
  width: 100px;
  margin-top: 8%;
  padding: 10px;
  border: 1px solid lightgray;
}
@media (max-width: 767px) {
  .userData,
  .designerDesign {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 460px) {
.attachmentStyles {
  margin: 10px 0px;
}
}