.userData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  margin-top: 20px;
  grid-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.userDataInfo {
  display: grid;
  grid-template-columns: 0.1fr auto 0.2fr;
  align-items: center;
  column-gap: 10px;
}

@media screen and (max-width: 960px) {
  .userDataInfo {
    display: inline-flex;
    flex-flow: row wrap;
    grid-template-columns: 0.1fr auto 0.2fr;
    align-items: center;
    column-gap: 10px;
  }
}

.userDataInfo .userDataImg img {
  height: 100px;
  width: 100px;
  border-radius: 40%;
}

.designerDesign {
  display: grid;
  grid-template-columns: 20% auto 20%;
  /* justify-content: space-around; */
  column-gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.designerDesignOptions {
  display: grid;
  justify-content: right;
}
.designerDesignOptions svg {
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 10px 0px;
}

.desingerDesignSubImage {
  display: grid;
  row-gap: 15px;
  overflow: scroll;
}

.desingerDesignSubImage img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid white;
  cursor: pointer;
}
.desingerDesignSubImage img.active {
  border: 3px solid black;
}
.designerDesignMainImage {
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
}
.designerDesignMainImage img {
  height: 550px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1350px) {
  .desingerDesignSubImage {    
    display: inline-flex;
    flex-flow: row wrap;
    margin-block-start: inherit;
    width: 100%;
    padding: 0px 83px;
  }
  .designerDesign {
  display: inline-flex;
  flex-flow: row wrap;
  }
}
@media screen and (max-width: 1240px) {
  .designerDesignMainImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .designerDesignOptions {
    display: inline-flex;
    flex-flow: row wrap;
  }
  .desingerDesignSubImage {
    margin-block-start: 10px;
    display: inline-flex;
    flex-flow: row wrap;
    margin-block-start: inherit;
    width: 100%;
    padding: 0;    
  }
  .designDescription2 {
    display: block;
  }
}

.designerDesingData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 1080px) {
  .designerDesingData {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: space-around !important;
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .DesignDataAttr {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: flex-end;
  }
}

.designerDesignDataAttr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designerDesignDataAttr p {
  display: flex;
  align-items: center;
  column-gap: 7px;
  border-right: 1px solid black;
  padding-right: 30px;
}

.designDescription {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  font-weight: bold;
  margin: 7rem 0px;
}

.designerData {
  display: grid;
  text-align: center;
  position: relative;
}

.designerData::before {
  content: "";
  border: 0.5px solid black;
  width: 100%;
  top: 25%;
  position: absolute;
}

.designerData img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  z-index: 9;
  border-radius: 40px;
}

.promotePopup {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.promoteContainer {
  background-color: white;
  padding: 20px;
  width: 30vw;
}

.promoteTitle img {
  margin-top: -5%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.promoteTitle.imgtitle img {
  border-radius: 0% !important;
}
.MuiSlider-markLabel {
  white-space: pre-wrap !important;
}
.containerTitle {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.promoteMainContainer {
  padding: 10px 30px;
}
.MuiSlider-marked {
  margin: 20px 0 !important;
}

.promoteSummary,
.promoteFooter {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.promoteTitle {
  background: lightgrey;
  margin: -20px;
  text-align: center;
  height: 180px;
}

.promotePopupCloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.containerFormControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerFormControl p {
  margin-bottom: 5px;
  font-weight: bold;
}
@media (max-width: 767px) {
  .designerDesign,
  .designerDesingData,
  .designDescription {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .designerDesignDataAttr {
    flex-wrap: wrap;
  }
  .designerDesignOptions {
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  }
}
