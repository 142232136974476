.main-container-sub-component{
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-left: 30px;
    margin-top: 30px;
}

.main-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.main-header-filter{
    margin-right: 30px;
}

.main-header-select{
    color: grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main-header-image{
    width: 15px;
    height: 15px;
    margin-right: 10px;
}

.card-container{
    display: grid;
    flex-direction: row;
    margin-bottom: 20px;
    flex-wrap: wrap;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
}

.card{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-top: 30px;
}

.card-image{
    width: 300px;
    height: 250px;
    border-radius: 5%;
}

.card-image-div{
    position: relative;
}

.card-image-div:hover{
    cursor: pointer;
}

.card-image-overlay{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: black; 
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 25px;
}

.card-image-div:hover .card-image-overlay{       
    opacity: 0.7;        
}        

.card-image-div:hover .card-image{
    /*opacity: 0.3;       */
}



.card-image-button{
    background-color:white;
    color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

.card-image-trending-button{
    
}

.card-image-edit-button{
}

.card-image-div:hover .card-image-trending-button{
}

.card-image-div:hover .card-image-edit-button{
}


.card-image-button img{
    width: 20px;
    height: 20px;
}

.card-details{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    width: 300px
}

.card-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card-icons-icon{
    width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-right: 3px;
}

.card-icons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: grey;
}
