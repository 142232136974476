.menu {
  background: #ffffff;
  color: black;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 10px;
  width: 155px !important;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu .active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
  background-color: white;
}

.menu li button {
  text-decoration: none;
  color: grey !important;
  text-align: left;
  background-color: white !important;
  display: block;
  border: none;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.menu li button:hover {
  background-color: #dddddd !important;
}

.menu li button:first-child {
  padding-top: 10px;
}

.menu li button:last-child {
  padding-bottom: 10px;
}

.menu-trigger {
  background: #ffffff;
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  border-radius: 90px;
}

@media (max-width: 767px) {
  .menu-trigger img {
    width: 30px !important;
    height: auto !important;
  }
}