.followers_div{
    display: flex;
    justify-content: space-around;
    margin-top: 3%;
}
.followers_sub_div{
    display:flex;
    margin-bottom: 10%;
    justify-content: center;
}
.profile_pic{
    height: 100px;
    border-radius: 42px;
}
.left_div{
    flex-grow: 0.05;
}
.right_div{
    line-height: 15px;
    width: 50%;
}
#role{text-transform: uppercase;}
#club_role{
    background: black;
    color: white;
    padding: 2px 10px;
}
#grp_role{
    border: 1px solid gray;
    padding: 2px 10px; 
}
#done_icon{
    font-size: 18px;
    float: left;
}
#horizonIcon{
    position: absolute;
    margin-left: 8px;
}
#city{margin-left: 4%; color: gray;}
.buttons_div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.buttons_div button{
    height: fit-content;
    padding: 9px;
    border-radius: 8px;
    font-weight: bold;
}
#following, #subscribed{
    background: black;
    color: white;
    border: none;
}
#not_following, #not_subscribed{
    background: transparent;
    border: 1px solid lightgray;
}
.buttons_div button:nth-child(2){margin-left: 4%;}
#follower_bio{color:#3B3B3B;}
.follower_count{
    line-height: 6px;
    font-size: small;
    margin-left: 4%;
}
.follower_count p:nth-child(1){
    font-weight: bold;
}
.follower_count p:nth-child(2){
    color: gray;
}
.followers_dropdown{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}