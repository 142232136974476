.notify_header{
    display: flex;
    justify-content: space-evenly;
    gap:350px;
    align-items: baseline;
}
.notify_header h1{
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.notify_content{
   border: 1px solid lightgray;;
    border-radius: 16px;
}
.notify_main{
    width: 85%;
    margin: 3% auto;
    display: block;
}
.notify_content_inner{
    margin: 35px 40px;
    padding: 0px 17px;
    
}
.notify_content div:nth-child(odd) .notify_content_inner{
    border-left: 5px solid black;
}
.notify_content div:nth-child(even) .notify_content_inner{
    border-left: 5px solid lightgray;
}
.n_type{
    background: black;
    color: white;
    width: max-content;
    padding: 4px 15px;
    text-transform: capitalize;
    min-width: 65px;
}
.n_des{
    color: dimgray;
}
.n_user, .mark_read{
    font-weight:900;
}
.n_date{
    display: flex;
    align-items: center;
    color:dimgray
}
.notify_inner_div{
    display: flex;
    justify-content: space-between;
}
.div_check{
    position: absolute;
    margin-top: 5%;
    margin-left: 1%;
}
.notify_header_inner{
    display:flex;
    align-items:center;
    gap:25px
}