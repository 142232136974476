.design-collection-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 370px);
    justify-content: flex-start;
    justify-items: flex-start;
    grid-gap: 20px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 100%;
}



@media screen and (max-width: 768px) {

    .design-collection-container {
        justify-content: center;
    }

}
