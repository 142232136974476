.dezinerAudioPlayer {
  align-items: center;
  display: flex;
}


.dezinerPlayPause {
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items:center;
}

.dezinerPlayPause:hover {
  cursor: pointer;
}

.dezinerCurrentTime,
.dezinerDuration {
  font-family: monospace;
  font-size: 16px;
}

.dezinerCurrentTime {
  margin-left: 25px;
  color: gray;
}

.dezinerProgressBar {
  --seek-before-width: 0;

  appearance: none;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 26px;
  outline: none;

}

/* progress bar - safari */
.dezinerProgressBar::-webkit-slider-runnable-track {
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

/* progress bar - firefox */
.dezinerProgressBar::-moz-range-track {
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

.dezinerProgressBar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.dezinerProgressBar::before {
  content: '';
  height: 100%;
  width: var(--seek-before-width);
  max-width: 100%;
  background: url('/track-black.svg');
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: pointer;
}

/* progress bar - firefox */
.dezinerProgressBar::-moz-range-progress {
  content: '';
  height: 100%;
  width: var(--seek-before-width);
  background: url('/track-black.svg');
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: pointer;
}

/* knobby - chrome and safari */
.dezinerProgressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 26px;
  width: 2px;
  border-radius: 2px;
  border: none;
  background-color: #2b2a95;;
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  top: -7px;
}

/* knobby while dragging - chrome and safari */
.dezinerProgressBar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: #2b2a95;
}

/* knobby - firefox */
.dezinerProgressBar::-moz-range-thumb {
  height: 26px;
  width: 2px;
  border-radius: 2px;
  border: none;
  background-color: #2b2a95;;
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  top: -7px;
}

/* knobby while dragging - firefox */
.dezinerProgressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: #2b2a95;
}
