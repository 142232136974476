.design-view-grid-container {
  max-width: 1200px;
  margin: 50px auto;
}

.design-main-image-container {
  padding: 5px;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.design-main-image-container img {
  height: auto;
  max-width: 100%;
  min-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  max-height: 700px;
}

.design-main-image-container video {
    max-height: 700px;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
}

.design-item-card {
  widht: 100px;
  min-width: 100px;
  max-width: 100px;

  height: 75px;
  min-height: 75px;
  max-height:75px;
  object-fit: cover;
  border-radius: 20px;
  cursor: pointer;
}

.design-item-card-active {
  border: 1px solid black;
}

.design-view-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.design-view-stats hr {
  height: 30px;
  width: 2px;
}

.design-view-stats>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.designer-vertical-avatar{
  margin: 30px 0;
}

.designer-vertical-avatar::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: #e2e2e2;
  display: block;
  position: relative;
  top: 50px;
  z-index: -1;
  margin: auto;
}


.design-view-stats>div>span {
  padding: 0 5px;
}


@media screen and (max-width: 768px) {
  .design-view-grid-container {
    margin: 50px 30px;
  }

  .slick-slider {
    width: 100%
  }
}

/* ***********/
.userData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  margin-top: 20px;
  grid-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.userDataInfo {
  display: grid;
  grid-template-columns: 0.1fr auto 0.2fr;
  align-items: center;
  column-gap: 10px;
}

.userDataInfo .userDataImg img {
  height: 100px;
  width: 100px;
  border-radius: 40%;
}

.designerDesign {
  display: grid;
  grid-template-columns: 20% auto 20%;
  /* justify-content: space-around; */
  column-gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.designerDesignOptions {
  display: grid;
  justify-content: right;
}

.designerDesignOptions svg {
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
  margin: 10px 0px;
}

.desingerDesignSubImage {
  display: grid;
  row-gap: 15px;
  overflow: scroll;
}

.desingerDesignSubImage img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid white;
  cursor: pointer;
}

.desingerDesignSubImage img.active {
  border: 3px solid black;
}

.designerDesignMainImage {
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
}

.designerDesignMainImage img {
  height: 550px;
  width: 100%;
  object-fit: cover;
}

.designerDesingData {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  margin-top: 20px;
}

.designerDesignDataAttr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designerDesignDataAttr p {
  display: flex;
  align-items: center;
  column-gap: 7px;
  border-right: 1px solid black;
  padding-right: 30px;
}

.designDescription {
  display: grid;
  grid-template-columns: 20% auto 20%;
  column-gap: 20px;
  font-weight: bold;
  margin: 7rem 0px;
}

.designerData {
  display: grid;
  text-align: center;
  position: relative;
}

.designerData::before {
  content: "";
  border: 0.5px solid black;
  width: 100%;
  top: 25%;
  position: absolute;
}

.designerData img {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  z-index: 9;
  border-radius: 40px;
}

.promotePopup {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.promoteContainer {
  background-color: white;
  padding: 20px;
  width: 30vw;
}

.promoteContainer .promoteTitle img {
  margin-top: -5%;
  height: 100%;
  width: 350px;
}

.promoteTitle.imgtitle img {
  border-radius: 0% !important;
}

.MuiSlider-markLabel {
  white-space: pre-wrap !important;
}

.containerTitle {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

.promoteMainContainer {
  padding: 10px 30px;
}

.MuiSlider-marked {
  margin: 20px 0 !important;
}

.promoteSummary,
.promoteFooter {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.promoteTitle {
  background: lightgrey;
  margin: -20px;
  text-align: center;
  height: 180px;
}

.promotePopupCloseIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.containerFormControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerFormControl p {
  margin-bottom: 5px;
  font-weight: bold;
}

@media (max-width: 767px) {

  .userData,
  .designerDesign {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
