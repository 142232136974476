main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    background-color: #ccc;
    margin-top: 30px;
}
.filter-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 80px;
    border-right: 1px solid darkgrey;
    cursor: pointer;
}

.filter-item-active{
    color: white;
    background-color: black;
}

.filter-item img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.filter-item:last-child{
    border-right: none;
}

.filter-item p {
    margin: 0;
}

/* Design Card CSS */

.card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    margin-bottom: 50px;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
}

.card{
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-top: 30px;
}

.card-image{
    width: 300px;
    height: 250px;
    border-radius: 5%;
}

.card-image-div{
    position: relative;
}

.card-image-div:hover{
    cursor: pointer;
}

.card-image-overlay{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background: rgba(0, 0, 0, 0);
}

.card-image-div:hover .card-image-overlay{       
    opacity: 1;        
}        

.card-image-div:hover .card-image{
    opacity: 0.3;       
}

.card-image-button{
    background-color:white;
    color: white;
    border-radius: 50%;
}

.card-image-impression-button{
    position: absolute;
    left:240px;
    top: 20px;
    opacity: 0;
    transition: opacity .35s ease;
    cursor: pointer;
}

.card-image-plus-button{
    position: absolute;
    left:200px;
    top: 200px;
    opacity: 0;
    transition: opacity .35s ease;
    cursor: pointer;
}

.card-image-share-button{
    position: absolute;
    left:240px;
    top: 200px;
    opacity: 0;
    transition: opacity .35s ease;
    cursor: pointer;
}

.card-image-text{
    position: absolute;
    left:20px;
    top: 210px;
    opacity: 0;
    color: white;
    transition: opacity .35s ease;
    cursor: pointer;
}

.card-image-div:hover .card-image-impression-button{
    opacity: 1;
}

.card-image-div:hover .card-image-plus-button{
    opacity: 1;
}

.card-image-div:hover .card-image-share-button{
    opacity: 1;
}

.card-image-div:hover .card-image-text{
    opacity: 1;
}


.card-image-button img{
    width: 20px;
    height: 20px;
}

.card-details{
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    width: 300px;
}

.card-details-designer-info{
    display: flex;
    flex-direction: row; 
    align-items: center;

}

.card-details-designer-info img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
}

.card-details-designer-info span{
    font-weight: bold;
    font-size: 10px;
    margin-right: 20px;
    width: 100%;
    overflow-wrap: anywhere;
    min-width: 50px;
}

.card-icons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card-icons-icon{
    width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-right: 3px;
}

.card-icons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: grey;
}

.card-role-club{
    background-color: black;
    border:none;
    color: white;
    padding: 2px 16px;
}
.card-role-group{
    border: 1px solid black;
    color: black;
    padding: 2px 16px;

}
.card-role-designer{
    color: black;
    border: none;
}

/* Design Area CSS */

.design-area{
    width: 95%;
}

.design-area-header{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.design-area-header-left{ 
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    font-size: 20px;
}

.design-area-header-left img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.other-user-about{
    width: 40%;
    font-size: 14px;
    color: darkgrey;
    margin-top: 25px;
}