@media screen and (max-width: 1080px) {
  .RegistrationMainForm {
    display: inline-flex !important;
    flex-flow: row wrap;
  }

  .Verticle__line {
    height: 0px !important;
  }

  .socialLoginBtns GoogleLogin MicrosoftLogin {
    display: "inline-flex" !important;
    flex-flow: "row wrap" !important;
  }
  MicrosoftLogin {
    width: 100% !important;
  }
}
