/* dialog main css start */
.MuiDialog-paper{overflow-y: inherit !important;}
.Cdialog_wrapper{width:430px;}
.Cdialog_box{
    width: fit-content;
    height: auto;
    background-color: rosybrown;
    padding: 32px 33px;
    border-radius: 42px;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -11%;
}
.Cdialog_box h5{
    font-weight: 900;
    letter-spacing: 1px;
}
/* dialog main css end */

/* dialog title css start */
#Cform-dialog-title{text-align: center;}
#Cform-dialog-title h2{font-weight:900}
/* dialog title css end */

/* dialog content css start */
.Cdialog_content_main, .Cdialog_content_left{display: flex;}
.Cdialog_content_main{
    justify-content: space-between;
    margin: 36px auto;
}
.Cdialog_content_main button.adminLabel{
    display: flex;
    background: black;
    align-items: center;
    border-radius: 9px;
}
.Cdialog_content_main button.adminLabel span{
    color: white;
}
.Cdialog_content_left img{
    height: 40px;
    width: auto;
    border-radius: 16px;
    margin-right: 12px;
}
/* dialog content css end */

#Cmember_name{font-weight: 900;}
#Cmember_designation{color: dimgray;}

.Cmember_title{display: flex;}
.Cclose_icon{
    position: absolute;
    right: 7px;
    top: 10px;
    color: darkgray;
    cursor:pointer;
}

/* ################ Mobile screen css ################### */
@media (max-width:767px){
    .Club_main{
        margin-top:26%;
    }
    .Cdialog_wrapper{width:auto;}
    .MuiDialog-container { width: fit-content;}
}